import React from "react"
import TileList from "../../components/tilelist"
import SubnavList from "../../components/subnavList"
import Backstory from "../../components/backstory"

const PageContent = () => (
  <div className="pageContent">
    <h2>About Me</h2>
    <p>
      Always the explorer, you can catch me biking, driving, sailing, flying,
      and finding any creative way to get lost. I spend whatever time is left
      enjoying podcasts, books, and video games.
    </p>
    <p></p>
  </div>
)

const backstoryContent = () => {
  return (
    <div className="about">
      <div className="contentArea">
        <Backstory />
        <PageContent />
      </div>
      <TileList />
      <SubnavList />
    </div>
  )
}

export default backstoryContent;